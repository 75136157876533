@import url(https://fonts.googleapis.com/css?family=Indie+Flower);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.logo {
  font-family: 'Indie Flower', cursive;
  color: white;
  position: fixed;
  width: 380px;
}
.timer {
  text-align: center;
  border: 1px solid grey;
  min-height: 440px
}
.current-time-header {
  color: rgb(223, 221, 221);
}

.timer-help-text {
  color: rgb(223, 221, 221);
  margin-top: 2.5em;
}

.timer-controls-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 3em;
  font-weight: bold;
}

.minute-tens-control {
  padding-right: .3em;
}

.btn-start-timer {
  margin-top: 2em;    
  margin-bottom: 2em;    
} 

.login {
  padding-top: 2.5em;
}
.btn-new-checklist {
  margin-top: 1em;
}
.item-checkbox label {
  font-size: 1.3em;
  font-weight: 400;
}

.item-checkbox input {
  margin-right: 1.3em;
}
