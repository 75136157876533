.current-time-header {
  color: rgb(223, 221, 221);
}

.timer-help-text {
  color: rgb(223, 221, 221);
  margin-top: 2.5em;
}

.timer-controls-row {
  display: flex;
  justify-content: center;
  font-size: 3em;
  font-weight: bold;
}

.minute-tens-control {
  padding-right: .3em;
}

.btn-start-timer {
  margin-top: 2em;    
  margin-bottom: 2em;    
} 
